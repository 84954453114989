import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import swal from 'sweetalert2'
import moment from 'moment'
import { resetPayout, confirmPayout, resetConfirmedPayout, cancelPayout, resetCancelPayout } from 'actions/payout'
import { putDeclareConversion, resetPutDeclareConversion } from 'actions/declareConversion'
import { putRetryConversion, resetRetryConversion} from 'actions/retryConversion'
import { putCancelConversion, resetCancelConversion } from 'actions/cancelConversion'
import DataPresentationRow from 'components/ui_elements/data_presentation_row'
import Tooltip from 'components/ui_elements/tooltip'
import NumberFormatter from 'components/ui_elements/number_formatter'
import DeclareConversionModal from 'components/declare_conversion/declare_conversion_modal'
import { secureStorage } from 'helpers/secure_storage_helper'
import { initAlert } from 'helpers/alert_helper'
import { getStatusClassname } from 'helpers/class_helper'
import { fullTimeUtc } from 'helpers/date_helper'
import { dateToIsoFull } from 'helpers/date_helper'
import { EXPLORERS_TX, EXPLORERS_ADDRESS } from 'helpers/block_explorers'
import { ENV } from "index"
import { feeMultiplied, percentMultiplied } from 'helpers/fees_helper'

   

const TransactionsTableInner = ({val, handleResendNotification, showProfitModal, showConfirmModal, transactionsGetHistory}) => {
    const dispatch = useDispatch();
    const currencies = useSelector(state => state.currencies);
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchant = useSelector(state => state.isMerchant);
    const isMerchantUser = useSelector(state => state.isMerchantUser);
    const isMerchantAdmin = useSelector(state => state.isMerchantAdmin);
    const cancelledPayout = useSelector(state => state.cancelledPayout);
    const confirmedPayout = useSelector(state => state.confirmedPayout);
    const declaredConversion = useSelector(state => state.declaredConversion);
    const retriedConversion = useSelector(state => state.retriedConversion);
    const cancelledConversion = useSelector(state => state.cancelledConversion);
    const merchantId = secureStorage.getItem('merchantId');
    const [blockchain, setBlockchain] = useState();
    const [coinExplorer, setCoinExplorer] = useState();
    const [coinExplorerAddress, setCoinExplorerAddress] = useState();
    const [cancellingPayoutLoading, setCancellingPayoutLoading] = useState(false);
    const [conversionDeclaringLoading, setConversionDeclaringLoading] = useState(false);
    const [retryingConversionLoading, setRetryingConversionLoading] = useState(false);
    const [cancellingConversionLoading, setCancellingConversionLoading] = useState(false);
    const [confirmationSending, setConfirmationSending] = useState(false);
    const [showDeclareConversionModal, setShowDeclareConversionModal] = useState(false);
    const disableBtnProps = {};
    if (val.profitDetails === undefined) {
        disableBtnProps.disabled = true;
    }
    useEffect(() => {
        if(cancellingPayoutLoading === true) {
            if(!_.isEmpty(cancelledPayout)) {
                if(cancelledPayout.type === 'error') {
                    initAlert({
                        type: 'error', 
                        title: 'Error', 
                        html: cancelledPayout.response.message
                    });
                } else {
                    initAlert({
                        type: 'success', 
                        title: 'Payout canceled', 
                        html: 
                            'Your payout has been successfully canceled'
                    });
                }
                setCancellingPayoutLoading(false);
                transactionsGetHistory();
            }
        }
    }, [cancelledPayout]);

    useEffect(() => {
        if(retryingConversionLoading === true) {
            if(!_.isEmpty(retriedConversion)) {
                if(retriedConversion === 'error' || retriedConversion.status === '400') {
                    initAlert({
                        type: 'error', 
                        title: 'Error', 
                        html: retriedConversion.data ? retriedConversion.data : 'Some error occured, please try again'
                    });
                } else {
                    initAlert({
                        type: 'success', 
                        title: 'Conversion done', 
                        html: 
                            'Conversion retry has been successfully executed'
                    });
                }
                dispatch(resetRetryConversion());
                setRetryingConversionLoading(false);
                transactionsGetHistory();
            }
        }
    }, [retriedConversion]);

    useEffect(() => {
        if(cancellingConversionLoading === true) {
            if(!_.isEmpty(cancelledConversion)) {
                if(cancelledConversion === 'error' || cancelledConversion.status === '400') {
                    initAlert({
                        type: 'error', 
                        title: 'Error', 
                        html: cancelledConversion.data ? cancelledConversion.data : 'Some error occured, please try again'
                    });
                } else {
                    initAlert({
                        type: 'success', 
                        title: 'Conversion cancelled', 
                        html: 
                            'Conversion has been successfully cancelled'
                    });
                }
                dispatch(resetCancelConversion());
                setCancellingConversionLoading(false);
                transactionsGetHistory();
            }
        }
    }, [cancelledConversion]);

    useEffect(() => {
        if(!_.isEmpty(confirmedPayout) && confirmedPayout !== undefined && confirmationSending) {
            if(confirmedPayout.type === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: confirmedPayout.response
                });
                dispatch(resetPayout());
                transactionsGetHistory();
                setConfirmationSending(false);
                return;
            }
            handleSuccess(confirmedPayout);
            dispatch(resetConfirmedPayout());
            setConfirmationSending(false);
        }
    }, [confirmedPayout]);

    useEffect(() => {
        if(currencies.length) {
            let currency = currencies.find(curr => curr.code === val.transferCurrency);
            if(currency) {
                setBlockchain(currency.blockchain);
            }
        }
    }, [currencies]);
    useEffect(() => {
        if(blockchain) {
            setCoinExplorer(EXPLORERS_TX[blockchain]);
            setCoinExplorerAddress(EXPLORERS_ADDRESS[blockchain]);
        }
    }, [blockchain]);
    useEffect(() => {
        if(declaredConversion && conversionDeclaringLoading === true) {
            if(declaredConversion !== '') {
                if(declaredConversion.type === 'error') {
                    initAlert({
                        type: 'error', 
                        title: 'Error', 
                        html: declaredConversion.response
                    });
                } 
                if(declaredConversion === 200) {
                    initAlert({
                        type: 'success', 
                        title: 'Conversion declared', 
                        html: 
                            'Conversion amount has been successfully declared'
                    });
                }
                dispatch(resetPutDeclareConversion());
                setConversionDeclaringLoading(false);
                transactionsGetHistory();
            }
        }
    }, [declaredConversion]);
    const handleCancelClick = (id) => {
        initAlert({
            type: 'warning', 
            title: 'Payout cancellation',
            html: 
                'Your requested payout cancelation' +
                '<br />' +
                'do you want to continue?' +
                '<br />',
            confirmText: 'Yes, cancel payout',  
            cancelText: 'Exit',
            onSuccess: () => cancellingPayout(id), 
            onDismiss: onCanceled
        });
    }
    const handleRetryConversion = (id) => {
        initAlert({
            type: 'warning', 
            title: 'Retrying conversion',
            html: 
                'Your requested conversion retry' +
                '<br />' +
                'do you want to continue?' +
                '<br />',
            confirmText: 'Yes, retry conversion',  
            cancelText: 'Exit',
            onSuccess: () => retryingConversion(id), 
            onDismiss: onCanceled
        });
    }
    const handleCancelConversion = (id) => {
        initAlert({
            type: 'warning', 
            title: 'Cancelling conversion',
            html: 
                'Your requested conversion cancellation' +
                '<br />' +
                'do you want to continue?' +
                '<br />',
            confirmText: 'Yes, cancel',  
            cancelText: 'Exit',
            onSuccess: () => cancellingConversion(id), 
            onDismiss: onCanceled
        });
    }
    const cancellingPayout = (id) => {
        dispatch(cancelPayout(id));
        setCancellingPayoutLoading(true);
    }
    const retryingConversion = (id) => {
        dispatch(putRetryConversion(id));
        setRetryingConversionLoading(true);
    }
    const cancellingConversion = (id) => {
        dispatch(putCancelConversion(id));
        setCancellingConversionLoading(true);
    }
    const onCanceled = () => { return }; 
    const handleDeclareConversion = () => {
        setShowDeclareConversionModal(true);
    }
    const modalDeclareConversionClosed = () => {
        setShowDeclareConversionModal(false);
    }
    const onDeclaredConversion = (boughtAmount) => {
        dispatch(putDeclareConversion({
            boughtAmount,
            conversionId: val.conversionId
        }));
        setConversionDeclaringLoading(true);
    }
    const newPayoutSubmit = ({transferAmount, priceAmount, address, destinationDetails, transferCurrency, priceCurrency, id, expirationTime}) => {
        let timeNow = dateToIsoFull(new Date());
        let payoutMessage = 
            'Your payout request have been calculated for' +
                '<br />' +
                '<div class="card mrg-top-15 no-mrg-btm">' +
                    '<div class="card-block card-block-small">' +
                        '<p class="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                            '<span class="text-dark font-size-18">' +
                                '<b>' + transferAmount + ' ' + transferCurrency +'</b>' +
                            '</span>' +
                        '</p>' +
                    '</div>' +
                '</div>' +
                '<br />' +
                'and will cost' +
                '<br />' +
                '<div class="card mrg-top-15 no-mrg-btm">' +
                    '<div class="card-block card-block-small pdd-left-15 pdd-right-15">' +
                        '<p class="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                            '<span class="text-dark font-size-18">' +
                                '<b>' + priceAmount + ' ' + priceCurrency + '</b>' +
                            '</span>' +
                        '</p>' +
                    '</div>' +
                '</div>' +
                '<br />';
        
            if(transferCurrency === 'EUR') {
                payoutMessage +=
                    'Bank details:' +
                    '<br />' +
                    '<div class="card mrg-top-15 no-mrg-btm">' +
                        '<div class="card-block pdd-15">' +
                            '<p>Bank: <b>' + destinationDetails.bankName + '</b></p>' +
                            '<p>IBAN: <b>' + destinationDetails.iban + '</b></p>' +
                            '<p>Swift: <b>' + destinationDetails.swift + '</b></p>' +
                            '<p className="no-mrg-btm">Account number: <b>' + destinationDetails.accountNumber + '</b></p>' +
                        '</div>' +
                    '</div>' +
                    '<br />' +
                    'This exchange rate is valid for <b id="timeLeft"></b>' +
                    '<br />' +
                    'Do you want to continue?' +
                    '<br />' +
                    '<br />' +
                    '<div id="myProgress" className="mrg-top-30 mrg-btm-30 hide">' +
                        '<div id="myBar">' +
                        '</div>' +
                    '</div>';

            } else {
                if(address !== null) {
                    payoutMessage += 
                        'sending address is:' +
                        '<br />' +
                        '<div className="card mrg-top-15 no-mrg-btm">' +
                            '<div className="card-block card-block-small pdd-left-15 pdd-right-15">' +
                                '<p className="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                                    '<span className="text-dark font-size-18">' +
                                        '<b>' + address + '</b>' +
                                    '</span>' +
                                '</p>' +
                            '</div>' +
                        '</div>' +
                        '<br />' +
                        '<br />' +
                        'This exchange rate is valid for <b id="timeLeft"></b>' +
                        '<br />' +
                        'Do you want to continue?' +
                        '<br />' +
                        '<br />' +
                        '<div id="myProgress" className="mrg-top-30 mrg-btm-30 hide">' +
                            '<div id="myBar">' +
                            '</div>' +
                        '</div>';
                } else {
                        payoutMessage += 
                            '<br />' +
                            'This exchange rate is valid for <b id="timeLeft"></b>' +
                            '<br />' +
                            'Do you want to continue?' +
                            '<br />' +
                            '<br />' +
                            '<div id="myProgress" className="mrg-top-30 mrg-btm-30 hide">' +
                                '<div id="myBar">' +
                                '</div>' +
                            '</div>';
                }

        }
        initAlert({
            type: 'info', 
            title: 'Payout calculated', 
            html: payoutMessage, 
            confirmText: 'Yes, send ' + transferCurrency,  
            cancelText: 'Exit',
            onSuccess: () => sendCoin(id), 
            onDismiss: onCanceled
        });
        timerDisplay(expirationTime, timeNow);
    }
    
    const sendCoin = (id) => {
        dispatch(confirmPayout(id));
        setConfirmationSending(true)
    }

    const handleSuccess = (val) => {
        let el = val.data;
        initAlert({
            type: 'success', 
            title: 'Started processing', 
            html: 
                'Your payout is now being processed' +
                '<br/>' +
                '<br/>' + 
                '<div class="col-md-12">' +
                    '<div class="card no-mrg-top">' +
                        '<div class="card-block card-block-success">' +
                            '<div>' +
                                '<p class="clearfix mrg-btm-15">' +
                                    '<span class="pull-left font-size-14">Total ' + el.transferCurrency + ' to send:</span>' +
                                    '<span class="pull-right text-dark font-size-18">' + el.transferAmount + ' ' + el.transferCurrency + '</span>' + 
                                '</p>' +
                            '</div>' +
                            '<div>' +
                                '<p class="no-mrg-btm clearfix">' +
                                    '<span class="pull-left">Total price: </span>' +
                                    '<span class="pull-right text-dark font-size-18"><b>' + el.priceAmount + ' ' + el.priceCurrency + '</b></span>' +
                                '</p>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<br/>' +
                'After processing is completed and payout is sent status in the system will change from <b>QUEUED</b> to <b>SENT</b>.'
        });
        dispatch(resetPayout());
        transactionsGetHistory();
        if(isMerchant || isMerchantUser || isMerchantAdmin) {
            //dispatch(getPayoutLimit('EUR', true));
        }
    }

    const timerDisplay = (expirationTime, timeNow) => {
        let date1 = moment(timeNow, 'YYYY-MM-DD HH:mm:ss');
        let date2 = moment(expirationTime, 'YYYY-MM-DD HH:mm:ss');
        let diff = date2.diff(date1, 'seconds');
        let elem = document.getElementById('myBar'); 
        let text = document.getElementById('timeLeft');
        let hour = 3600;
        let width = 100;
        let endText = '';
        let id = setInterval(frame, 1000);
        let started = true;
        function frame() {
            if (width <= 0) {
                swal.close();
                transactionsGetHistory();
                clearInterval(id);
            } else {
                diff--;
                width = diff / hour * 100;
                date2 = moment(date2).subtract(1, 'seconds');
                let newDate = moment.utc(moment(date2).diff(moment(date1)));
                endText = newDate.format('HH') === '00' ? newDate.format('mm:ss') + ' min' : newDate.format('HH:mm:ss') + ' min';
                text.innerHTML = endText;
                elem.style.width = width + '%';
                if (started === true) {
                  document.getElementById('myProgress').classList.remove('hide');
                  started = false;
                }
                if(width < 50) {
                  elem.classList.add('warning');
                } 
                if(width < 20) {
                  elem.classList.remove('warning')
                  elem.classList.add('danger');
                }
            }
        }
    }
    let destinationDetails = val.destinationDetails;
    return (
        <div className='pdd-horizon-15 pdd-vertical-20'>
            <div className='row'>
                <div className={val.transactionType === 'PAYMENT' ? 'col-md-7 border-right' : 'col-md-7'}>
                    <h4 className='mrg-btm-20'>Additional info:</h4>
                    {val.transactionType === 'PAYMENT' && val.address &&
                        <DataPresentationRow name='Coin address'>
                            <p>
                                {coinExplorerAddress ? (
                                    <a href={coinExplorerAddress + val.address} target='_blank' rel="noopener noreferrer">{val.address}</a>
                                ) : (
                                    <span>{val.address}</span>
                                )}
                            </p>
                        </DataPresentationRow>
                    }
                    
                    {destinationDetails && destinationDetails.address && destinationDetails.destinationType === 'CRYPTO' &&
                        <DataPresentationRow name='Coin address'>
                            <p>
                                {coinExplorerAddress ? (
                                    <a href={coinExplorerAddress + destinationDetails.address} target='_blank' rel="noopener noreferrer">{destinationDetails.address}</a>
                                ) : (
                                    <span>{destinationDetails.address}</span>
                                )}
                            </p>
                        </DataPresentationRow>
                    }
                    {val.transactionType === 'PAYMENT' || val.transactionType === 'PAYOUT' ? (
                        <DataPresentationRow name={val.transactionType === 'PAYMENT' ? 'Payment ID' : 'Payout ID'}>
                            {val.transactionType === 'PAYMENT' ? (
                                <p><a href={ENV.PAYMENT_PAGE_URL + '/' + val.paymentId} target='_blank' rel="noopener noreferrer">{val.paymentId}</a></p>
                            ) : (
                                <p>{val.payoutId}</p>
                            )}
                        </DataPresentationRow>
                    ) : (<></>)}
                    {val.conversionId &&
                        <DataPresentationRow name='Conversion ID'>
                            <p>{val.conversionId}</p>
                        </DataPresentationRow>
                    }
                    {val.conversionId &&
                        <DataPresentationRow name='Balance sold'>
                            <p><NumberFormatter toFormat={val.priceAmount} currCode={val.priceCurrency} /> {val.priceCurrency}</p>
                        </DataPresentationRow>
                    }
                    {destinationDetails && destinationDetails.transactionhash && destinationDetails.transactionhash.length ? (
                        <DataPresentationRow name='Transaction hash'>
                            <p>
                                {coinExplorer ? (
                                    <a href={coinExplorer + destinationDetails.transactionhash} target='_blank' rel="noopener noreferrer">{destinationDetails.transactionhash}</a>
                                ) : (
                                    <span>{destinationDetails.transactionhash}</span>
                                )}
                            </p>
                        </DataPresentationRow>
                    ) : (
                        <div></div>
                    )}
                    
                    {val.externalPaymentId &&
                        <DataPresentationRow name='External payment ID'>
                            <p>{val.externalPaymentId}</p>
                        </DataPresentationRow>
                    }
                    {val.exchangeRate &&
                        <DataPresentationRow name='Exchange rate'>
                            <p><NumberFormatter toFormat={val.exchangeRate} currCode={val.priceCurrency} overridePrecision={4} /> {val.priceCurrency}/{val.transferCurrency}</p>
                        </DataPresentationRow>
                    }
                    {val.status === 'INVALID' && val.transactionType === 'PAYMENT' &&
                        [
                            <div key='paidPriceAmount'>
                                {val.paidPriceAmount ? (
                                    <DataPresentationRow name='Actual paid'>
                                        <p className='text-warning'><NumberFormatter toFormat={val.paidPriceAmount} currCode={val.priceCurrency} /> {val.transferCurrency}(<NumberFormatter toFormat={val.paidTransferAmount} currCode={val.transferCurrency} /> {val.transferCurrency})</p>
                                    </DataPresentationRow>
                                ) : (
                                    <div></div>
                                )
                                }
                            </div>,
                            <div key='remainingFiat'>
                                {val.remainingToPayPrice ? (
                                    <DataPresentationRow name={val.remainingToPayPrice > 0 ? 'Underpaid by' : 'Overpaid by'}>
                                        <p className='text-warning'>{val.remainingToPayPrice ? <NumberFormatter toFormat={val.remainingToPayPrice} currCode={val.priceCurrency} /> : 0.00} {val.priceCurrency} ({val.remainingToPayTransfer ? <NumberFormatter toFormat={val.remainingToPayTransfer} currCode={val.transferCurrency} /> : '0.00'} {val.transferCurrency})</p>
                                    </DataPresentationRow>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        ]
                    }
                    {val.status === 'EXPIRED' &&
                        <DataPresentationRow name='Expired at'>
                            <p>{fullTimeUtc(val.expirationTime)}</p>
                        </DataPresentationRow>
                    }
                    {val.sentTime &&
                        <DataPresentationRow name='Sent at'>
                            <p>{fullTimeUtc(val.sentTime)}</p>
                        </DataPresentationRow>
                    }
                    {val.notificationType &&
                        <DataPresentationRow name='Notification type'>
                            <p>{val.notificationType}</p>
                        </DataPresentationRow>
                    }
                    {val.comment && val.comment !== '' &&
                        <DataPresentationRow name='Comment'>
                            <p>{val.comment}</p>
                        </DataPresentationRow>
                    }
                    {isAdmin && val.adminComment && val.adminComment !== '' &&
                        <DataPresentationRow name='Admin comment'>
                            <p>{val.adminComment}</p>
                        </DataPresentationRow>
                    }
                    {val.merchantComment && val.merchantComment !== '' &&
                        <DataPresentationRow name={isAdmin ? 'Merchant comment' : 'Comment'}>
                            <p>{val.merchantComment}</p>
                        </DataPresentationRow>
                    }
                    {val.apiKeyId &&
                        <DataPresentationRow name='API key ID'>
                            <p>{val.apiKeyId}</p>
                        </DataPresentationRow>
                    }
                    {val.transactionType === 'PAYOUT' && destinationDetails && destinationDetails.destinationType === 'FIAT' && 
                        <>
                            {destinationDetails.bankName &&
                                <DataPresentationRow name='Bank name'>
                                    <p>{destinationDetails.bankName}</p>
                                </DataPresentationRow>
                            }
                            {destinationDetails.swift &&
                                <DataPresentationRow name='Swift'>
                                    <p>{destinationDetails.swift}</p>
                                </DataPresentationRow>
                            }
                            {destinationDetails.accountNumber &&
                                <DataPresentationRow name='Account number'>
                                    <p>{destinationDetails.accountNumber}</p>
                                </DataPresentationRow>
                            }
                            {destinationDetails.iban &&
                                <DataPresentationRow name='IBAN'>
                                    <p>{destinationDetails.iban}</p>
                                </DataPresentationRow>
                            }
                        </>
                    }
                    {val.profit?.fxFeePercentage && isAdmin ? (
                        <DataPresentationRow name='FX fee applied'>
                            <p>{feeMultiplied(val.profit.fxFeePercentage)}%</p>
                        </DataPresentationRow>
                    ) : <></>}
                    {val.profit?.fxFeePercentage && isAdmin ? (
                        <DataPresentationRow name='TX fee applied'>
                            <p>{feeMultiplied(val.profit?.txFeePercentage)}%</p>
                        </DataPresentationRow>
                    ) : <></>}
                    {val.executionIds?.length && isAdmin ? (
                        <DataPresentationRow name='Executions'>
                            {val.executionIds.map(ex => <p><a href={ENV.EXECUTIONS_URL + '/execution/' + ex} target='_blank' rel="noopener noreferrer">{ex}</a></p>)}
                        </DataPresentationRow>
                    ) : <></>}
                </div>
                
                    <div className={val.transactionType === 'PAYMENT' || (val.transactionType === 'PAYOUT' && destinationDetails && destinationDetails.destinationType === 'FIAT') ? 'col-md-5 pdd-left-25' : 'col-md-5 pdd-left-25 flex-container'}>
                        {val.transactionType === 'PAYOUT' && destinationDetails && destinationDetails.destinationType === 'FIAT' && 
                            <div className='col-md-12 mrg-top-50 no-pdd-left'>
                                {destinationDetails.companyName &&
                                    <DataPresentationRow name='Company name'>
                                        <p>{destinationDetails.companyName}</p>
                                    </DataPresentationRow>
                                }
                                {destinationDetails.address &&
                                    <DataPresentationRow name='Address'>
                                        <p>{destinationDetails.address}</p>
                                    </DataPresentationRow>
                                }
                                {destinationDetails.postcode &&
                                    <DataPresentationRow name='Postcode'>
                                        <p>{destinationDetails.postcode}</p>
                                    </DataPresentationRow>
                                }
                                {destinationDetails.country &&
                                    <DataPresentationRow name='Country'>
                                        <p>{destinationDetails.country}</p>
                                    </DataPresentationRow>
                                }
                            </div>
                        }
                        {val.transactionType === 'PAYMENT' ? (
                            <>
                                <h4 className='mrg-btm-20'>Changes in state: <div className='mrg-top--2 inline-block'><Tooltip id='changesInStateTooltip' placement='top' text='Payment events' /></div></h4>
                                {val.events && val.events.map(function(el,j){
                                    let inner;
                                    let stateClass;
                                    let text = '';
                                    if(el.source === 'Bitclear') {
                                        stateClass = getStatusClassname(el.status);
                                        text = el.status === 'NEW' ? 'new payment request' : 'payment ' + el.status.toLowerCase();
                                        inner = (
                                            <p className='relative'>
                                                <span className={stateClass}> </span>
                                                <span className='pdd-left-20'>{text}</span>
                                                {el.rejectedTime &&
                                                    <div>
                                                        <span><b className='text-danger'> rejected at {fullTimeUtc(el.rejectedTime)}</b></span>
                                                    </div>
                                                }
                                            </p>
                                        )
                                    } else {
                                        inner = (
                                            <p>
                                                <b><NumberFormatter toFormat={el.coinAmount} currCode={el.coinCurrency} /> {el.coinCurrency}</b> received
                                                {el.rejectedTime &&
                                                    <div>
                                                        <span> - <b className='text-danger'> rejected at {fullTimeUtc(el.rejectedTime)}</b></span>
                                                    </div>
                                                }
                                            </p>
                                        )
                                    }
                                    return (
                                        <div className='row text-data-presentation' key={j}>
                                            <div className='col-md-6'>
                                                {inner}
                                            </div>
                                            <div className='col-md-6'>
                                                <label>{fullTimeUtc(el.time)}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (<></>)}
                        
                            <>
                                <hr />
                                <div style={val.transactionType === 'PAYMENT' ? {} : {alignSelf: "flex-end"}} className='flex-container flex-full'>
                                    <h4 className='no-mrg-btm mrg-top-5'> </h4>
                                    <div className="btn-group" role="group" aria-label="Info">
                                        {val.transactionType === 'PAYOUT' && 
                                            <>
                                                {val.status ==='NEW' &&
                                                    <button className='btn btn-sm btn-success no-mrg-btm' onClick={() => newPayoutSubmit({transferAmount: val.transferAmount, priceAmount: val.priceAmount, address: destinationDetails.address, destinationDetails: destinationDetails, transferCurrency: val.transferCurrency, priceCurrency: val.priceCurrency, id: val.payoutId, expirationTime: val.expirationTime})}>
                                                        Send
                                                    </button>
                                                }
                                                {isAdmin && val.cancellable &&
                                                    <button type="button" onClick={() => handleCancelClick(val.payoutId)} className="btn btn-sm btn-default no-mrg-btm">Cancel</button>
                                                }
                                            </>
                                        }
                                        {isAdmin && val.status === 'QUEUED' && val.transactionType === 'PAYOUT' && val.transferCurrency === 'EUR' && <button className='btn btn-sm btn-success no-mrg-btm' onClick={() => showConfirmModal(val.transactionType === 'PAYMENT' ? val.paymentId : val.payoutId)}>Confirm</button>}
                                        {isAdmin && <button type="button" className="btn btn-default no-mrg-btm btn-sm" onClick={() => showProfitModal(val.profitDetails, val.transactionType === 'PAYMENT' ? val.paymentId : val.payoutId)} {...disableBtnProps}>Profit</button>}
                                        {isAdmin && val.status === 'IN_PROGRESS' && val.transactionType === 'CONVERSION' ? <button className='btn btn-sm btn-default no-mrg-btm' onClick={() => handleRetryConversion(val.conversionId)}>Retry</button> : <></>}
                                        {isAdmin && val.transactionType === 'CONVERSION' && val.status === 'IN_PROGRESS' ? (<button type='button' className="btn btn-default no-mrg-btm btn-sm" onClick={() => handleDeclareConversion(val.conversionId)}>Declare</button>) : (<></>)}
                                        {isAdmin && val.status === 'IN_PROGRESS' && val.transactionType === 'CONVERSION' ? <button className='btn btn-sm btn-default no-mrg-btm' onClick={() => handleCancelConversion(val.conversionId)}>Cancel</button> : <></>}
                                        {val.transactionType === 'PAYMENT' || val.transactionType === 'PAYOUT' ? (<button type='button' className="btn btn-default no-mrg-btm btn-sm" onClick={() => handleResendNotification(val.transactionType === 'PAYMENT' ? val.paymentId : val.payoutId, val.transactionType)}>Resend notification</button>) : (<></>)}
                                    </div>
                                </div>
                            </>
                        
                        
                    </div>
            </div>
            {showDeclareConversionModal === true &&
                <DeclareConversionModal showOnInit={true} boughtCurrency={val.transferCurrency} onModalClosed={modalDeclareConversionClosed} formSent={onDeclaredConversion} />
            }
        </div>
    )
}
TransactionsTableInner.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    val: PropTypes.object.isRequired,
    handleResendNotification: PropTypes.func.isRequired
}

export default TransactionsTableInner;
import { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, useLocation, useHistory, useSearchParams } from 'react-router-dom'
import { getTransactionsHistory, resetTransactionsHistory } from 'actions/transactionsHistory'
import { postPayment, resetPayment } from 'actions/payment'
import { resetConfirmedFiatPayout } from 'actions/confirmFiatPayout'
import { postActiveTabDashboard } from 'actions/activeTabDashboard'
import { getMerchantsPaginated, resetMerchantsPaginated } from 'actions/merchantsPaginated'
import { switchMerchant } from 'actions/switchMerchant'
import { resendPaymentNotification, resetResendPaymentNotification } from 'actions/resendPaymentNotification'
import { resendPayoutNotification, resetResendPayoutNotification } from 'actions/resendPayoutNotification'
import { getCurrencies } from 'actions/currencies'
import { columnsCollapse, getMeta, selectCurrentClient } from 'helpers/helpers'
import { initAlert } from 'helpers/alert_helper'
import { updateInputStateHook, handleSubmitHook} from 'helpers/form_helper'
import { calculatePages } from 'helpers/pagination_helper'
import { dateToIso, isoToDate, dateObjToDate, parseStartOfMonthDate, parseEndDate } from 'helpers/date_helper'
import { calculateTableHeight } from 'helpers/table_helper'
import { removeStorage } from 'helpers/helpers'
import { totalCommaPrecision } from 'helpers/number_helper.js'
import variables from 'styles/_variables.scss'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import LayoutContainer from 'components/layout/layout_container'
import Breadcrumbs from 'components/ui_elements/breadcrumbs'
import Selectize from 'components/ui_elements/selectize'
import Tooltip from 'components/ui_elements/tooltip'
import LoadingError from 'components/ui_elements/loading_error'
import DateRangePicker from 'components/ui_elements/date_range_picker'
import PaginationContainer from 'components/ui_elements/pagination_container'
import TransactionsTable from 'components/transactions/transactions_table'
import NewPaymentModalContainer from 'components/new_payment/new_payment_modal_container'

import { btcaddress, paymentid } from 'helpers/validator_helper'
import loader from 'images/loader.gif';
import PropTypes from 'prop-types'

const RangeContainer = styled.div`
    display: flex;
    .separator {
        padding: 0 5px;
        line-height: 37px;
    }
`


const TransactionsExplorer = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const merchantsNamesPaginated = useSelector(state => state.merchantsNamesPaginated);
    const transactionsHistory = useSelector(state => state.transactionsHistory);
    const resendPaymentNotificationState = useSelector(state => state.resendPaymentNotificationState);
    const resendPayoutNotificationState = useSelector(state => state.resendPayoutNotificationState);
    const newPayment = useSelector(state => state.newPayment);
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchantAdmin = useSelector(state => state.isMerchantAdmin);
    const isMerchantUser = useSelector(state => state.isMerchantUser);
    const isMerchant = useSelector(state => state.isMerchant);
    const isUnassignedMerchant = useSelector(state => state.isUnassignedMerchant);
    const activeTabDashboard = useSelector(state => state.activeTabDashboard);
    const account = useSelector(state => state.account);
    const currencies = useSelector(state => state.currencies);
    const confirmedFiatPayout = useSelector(state => state.confirmedFiatPayout);
    const [searchParams, setSearchParams] = useSearchParams();
  	let date = new Date();
    let startFull = parseStartOfMonthDate(date);
    let endFull = parseEndDate(date);
    let start = dateObjToDate(startFull);
    let end = dateObjToDate(endFull);
    
  	let meta = transactionsHistory.meta;
    let details = searchParams.get("details") ? searchParams.get("details") : meta !== undefined ? meta.details : '';
    let selectClient = searchParams.get("merchantId") ? searchParams.get("merchantId") : meta !== undefined ? meta.merchantId : '';
    let transactionType = searchParams.get("transactionType") ? searchParams.get("transactionType") : meta !== undefined ? meta.transactionType : '';
    let currency = searchParams.get("currency") ? searchParams.get("currency") : meta !== undefined ? meta.currency : '';
    let amountFrom = searchParams.get("amountFrom") ? searchParams.get("amountFrom") : meta !== undefined ? meta.amountFrom : '';
    let amountTo = searchParams.get("amountTo") ? searchParams.get("amountTo") : meta !== undefined ? meta.amountTo : '';
    let timeStart = searchParams.get("timeStart") ? isoToDate(searchParams.get("timeStart")) : meta !== undefined ? getMeta(meta.timeStart, start, 'parseDate') : '';
    let timeEnd = searchParams.get("timeEnd") ? isoToDate(searchParams.get("timeEnd")) : meta !== undefined ? getMeta(meta.timeEnd, end, 'parseDate') : '';
    
    const limit = 128;
    const merchantsLimit = 128;
    const now = end;
    const [allResults, setAllResults] = useState(0);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(calculatePages(allResults, limit));
    const [formDisabled, setFormDisabled] = useState(true);
    const [merchantsNamesLoaded, setMerchantsNamesLoaded] = useState(false);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [profitDetails, setProfitDetails] = useState({});
    const [searchedMerchant, setSearchedMerchant] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showProfitModal, setShowProfitModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [updatedMerchants, setUpdatedMerchants] = useState(false);
    const [currencyPayment, setCurrencyPayment] = useState();
    const [collapsed, setCollapsed] = useState(false);
    const [reloadType, setReloadType] = useState(false);
    const [reloadMerchant, setReloadMerchant] = useState(false);
    const [reloadCurrency, setReloadCurrency] = useState(false);
    const [reloadRangePicker, setReloadRangePicker] = useState(false);
    const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);
    const [formValues, setFormValues] = useState({
        isPayout: false,
        transactionType: '',
        details: '',
        paymentId: '',
        payoutId: '',
        endDateFrom: '',
        timeStart: '',
        timeEnd: '',
        transactionId: '',
        selectClient: '',
        amountFrom: '',
        amountTo: '',
        currency: '',
        status: ''
    });
    let tableHeight = calculateTableHeight(limit);
    let selectClientVal = selectCurrentClient(merchantsNamesPaginated, formValues.selectClient);
    let items = transactionsHistory.data?.data?.items;
    useEffect(() => {
        setFormValues({...formValues,
            details: getMeta(details, ''),
            endDateFrom: end,
            timeStart: timeStart ? timeStart : searchParams.size ? '' : start,
            timeEnd: timeEnd ? timeEnd : searchParams.size ? '' : end,
            transactionId: '',
            selectClient: selectClient,
            status: meta !== undefined ? getMeta(meta.status, '') : [],
            amountFrom: getMeta(amountFrom, ''),
            amountTo: getMeta(amountTo, ''),
            currency: getMeta(currency, ''),
            transactionType: transactionType
        });
        setInitialDataLoaded(true);
        updateMerchants();
        dispatch(getCurrencies());
    }, []);
    useEffect(() => {
        if(isAdmin) {
            updateMerchants();
        }
    }, [isAdmin]);
    useEffect(() => {
        if(initialDataLoaded) {
            //setInitialDataLoaded(false);
            if(activeTabDashboard.overriteTransactions === true) {
                dispatch(postActiveTabDashboard({...activeTabDashboard, overriteTransactions: false}));
            } else {
                transactionsGetHistory(formValues.selectClient);
            }
            
        }
    }, [initialDataLoaded]);

    useEffect(() => {
        if(formValues.transactionType === '') {
            setReloadType(true);
        }
    }, [formValues.transactionType]);
     useEffect(() => {
        if(reloadType === true) {
            setReloadType(false);
        }
    }, [reloadType]);

    useEffect(() => {
        if(formValues.selectClient === '') {
            setReloadMerchant(true);
        }
    }, [formValues.selectClient]);

     useEffect(() => {
        if(reloadMerchant === true) {
            setReloadMerchant(false);
        }
    }, [reloadMerchant]);

    useEffect(() => {
        if(reloadRangePicker === true) {
            setReloadRangePicker(false);
        }

    }, [reloadRangePicker])

    useEffect(() => {
        if(formValues.currency === '') {
            setReloadCurrency(true);
        }
    }, [formValues.currency]);

    useEffect(() => {
        if(reloadCurrency === true) {
            setReloadCurrency(false);
        }
    }, [reloadCurrency]);

    useEffect(() => {
        if(isMerchant) {
            if(merchantsNamesPaginated.length && (updatedMerchants === false)) {
                transactionsGetHistory(merchantsNamesPaginated[0].value);
                setFormValues({...formValues, selectCliennt: merchantsNamesPaginated[0].value});
                setUpdatedMerchants(true);
            }
        }
    }, [merchantsNamesPaginated]);

	useEffect(() => {
	  	if(transactionsHistory.data !== undefined) {
	  		if(!_.isEmpty(transactionsHistory.data.data)) {
	            columnsCollapse();
                if(transactionsHistory.data.data.paginationInfo.before === null) {
                    let newCount = transactionsHistory.data.data.paginationInfo.itemsCount;
                    if(newCount !== allResults) {
                        setAllResults(newCount);
                    }
                    setPages(calculatePages(newCount, limit));
                    setPage(0);
                } else {
                    setPage((prevPage) => prevPage + 1);
                }
	        }
            if(formDisabled === true) {
                setFormDisabled(false);
            }
            if(loadMoreDisabled === true) {
                setLoadMoreDisabled(false);
            }
	  	}
    }, [transactionsHistory]);

    useEffect(() => {
        if(!_.isEmpty(resendPaymentNotificationState)) {
            if (resendPaymentNotificationState.status == 200) {
                initAlert({
                    type: 'success',
                    title: 'Info',
                    html: 'Notification sent'
                });
                dispatch(resetResendPaymentNotification());
                return;
            }
            if(resendPaymentNotificationState.data === 'error') {
                initAlert({
                    type: 'error',
                    title: 'Error',
                    html: 'Some error occured, please try again'
                });
                dispatch(resetResendPaymentNotification());
                return;
            }
        }
    }, [resendPaymentNotificationState]);

    useEffect(() => {
        if(!_.isEmpty(resendPayoutNotificationState)) {
            if (resendPayoutNotificationState.status == 200) {
                initAlert({
                    type: 'success',
                    title: 'Info',
                    html: 'Notification sent'
                });
                dispatch(resetResendPayoutNotification());
                return;
            }
            if(resendPayoutNotificationState.data === 'error') {
                initAlert({
                    type: 'error',
                    title: 'Error',
                    html: 'Some error occured, please try again'
                });
                dispatch(resetResendPayoutNotification());
                return;
            }
        }
    }, [resendPayoutNotificationState]);

    useEffect(() => {
        if(!_.isEmpty(newPayment) && newPayment !== undefined && currencyPayment) {
            if(newPayment.type === 'error') {
                initAlert({
                    type: 'error',
                    title: 'Error',
                    html: newPayment.response
                });
                dispatch(resetPayment());
                return;
            }
            if(newPayment.status === 200) {
                initAlert({
                    type: 'success',
                    title: 'Payment created',
                    html:
                        'You have successfully created new payment for: ' +
                        '<br />' +
                        '<br />' +
                        '<h4 class="mrg-btm-15"><b>' + totalCommaPrecision({toFormat: newPayment.data.transferAmount, currCode: newPayment.data.transferCurrency, currencies}) + ' ' + newPayment.data.transferCurrency + '</b></h4>' +
                        'To see this payment go to Payment Page' +
                        '<br />' +
                        '<a class="btn btn-default mrg-top-30 mrg-btm-30 btn-long" target="_blank" rel="noopener noreferrer" href="' + newPayment.data.paymentPageUrl + '">Payment Page</a>' +
                        '<br />' +
                        'or click OK to continue',
                    confirmText: 'OK',
                    classNames: 'long'
                });
                dispatch(resetPayment());
                setCurrencyPayment(false);
            }
            sendRequest(limit, formValues);
            setCurrencyPayment(false);
        }
        updateMerchants();
	}, [newPayment]);
    useEffect(() => {
        if(!_.isEmpty(confirmedFiatPayout) && confirmedFiatPayout !== undefined) {
            if(confirmedFiatPayout.type === 'error') {
                initAlert({
                    type: 'error',
                    title: 'Error',
                    html: confirmedFiatPayout.response
                });
                dispatch(resetConfirmedFiatPayout());
                return;
            }
            if(confirmedFiatPayout.status === 200) {
                initAlert({
                    type: 'success',
                    title: 'Payout confirmed',
                    html: 'You have successfully confirmed this payout',
                    confirmText: 'OK',
                    classNames: 'long'
                });
                dispatch(resetConfirmedFiatPayout());
                sendRequest(limit, formValues);
                setCurrencyPayment(false);
            }
        }
    }, [confirmedFiatPayout]);

    const updateMerchants = () => {
        if(merchantsNamesPaginated.length === 0 && isAdmin && merchantsNamesLoaded === false) {
            let params = {
                limit: merchantsLimit,
                offset: 0,
                select: true
            };
            if(searchedMerchant !== '') {
                params.name = searchedMerchant;
            }
            dispatch(getMerchantsPaginated(params));
            setMerchantsNamesLoaded(true);
        }
    }
    const transactionsGetHistory = (merchantName) => {
        let dateFrom = dateToIso(formValues.timeStart);
        let dateTo = dateToIso(formValues.timeEnd);
        let params = {
            transactionType: transactionType,
            timeStart: dateFrom === 'Invalid date' ? formValues.timeStart === 'Invalid date' ? '' : formValues.timeStart : dateFrom,
            timeEnd: dateTo === 'Invalid date' ? formValues.timeEnd  === 'Invalid date' ? '' : formValues.timeEnd : dateTo,
            merchantId: merchantName,
            details: details,
            status: formValues.status,
            amountFrom: formValues.amountFrom,
            amountTo: formValues.amountTo,
            currency: formValues.currency
        }
        let queryParams = parseQueryParams(params);
        params.limit = limit;
        params.allStatuses = formValues.status === 'All';
        params.allResults = allResults;
        //dispatch(resetTransactionsHistory(true));
        dispatch(getTransactionsHistory({...params}));
        setSearchParams({...queryParams});
    }
    const showModalFunc = (el, mounted, isPayout) => {
        setShowModal(true);
        setFormValues({...formValues, transactionId: el, isPayout: isPayout});

    }
    const showConfirmModalFunc = (id) => {
        setShowConfirmModal(true);
        setFormValues({...formValues, transactionId: id});
    }
    const showPaymentModalFunc = () => {
        setShowPaymentModal(true);
    }
    const modalPaymentClosed = () => {
        setShowPaymentModal(false);
    }
    const modalConfirmClosed = () => {
        setShowConfirmModal(false);
        setFormValues({...formValues, transactionId: ''});
    }
    const modalClosed = () => {
        setShowModal(false);
        setFormValues({...formValues, transactionId: ''});
    }
    const showProfitModalFunc = (el, id) => {
        setProfitDetails(el);
        setShowProfitModal(true);
        setFormValues({...formValues, transactionId: id});
    }
    const modalProfitClosed = () => {
        setProfitDetails({});
        setShowProfitModal(false);
        setFormValues({...formValues, transactionId: ''});
    }
	const sendRequest = (limitVal, thisState, before) => {
	  	let selectClientValue;
        let dateFrom;
        let dateTo;
        if(thisState === transactionsHistory.meta) {
            dateFrom = thisState.timeStart;
            dateTo = thisState.timeEnd;
            selectClientValue = thisState.merchantId;
        } else {
            dateFrom = dateToIso(thisState.timeStart);
            dateTo =  dateToIso(thisState.timeEnd);
            selectClientValue = formValues.selectClient;
        }
        let params = {
            transactionType: thisState.transactionType,
            paymentId: thisState.paymentId,
            payoutId: thisState.payoutId,
            details: thisState.details,
            merchantId: selectClientValue,
            timeStart: dateFrom !== 'Invalid date' ? dateFrom : '',
            timeEnd: dateTo !== 'Invalid date' ? dateTo : '',
            status: thisState.status,
            amountFrom: thisState.amountFrom,
            amountTo: thisState.amountTo,
            currency: thisState.currency
        }
        let queryParams = parseQueryParams(params);
        params.limit = limitVal;
        params.allStatuses = thisState.status === 'All';
        if(before) {
            params.before = before;
        }
        //dispatch(resetTransactionsHistory(true));
        dispatch(getTransactionsHistory({...params}));
        setSearchParams({...queryParams});
	}
    const parseQueryParams = (params) => {
        let queryUpdateValues = {};
        for (const elem in params) {
            if(params[elem] !== '' && params[elem] !== undefined) {
                queryUpdateValues[elem] = params[elem];
            }
        }
        return queryUpdateValues;
    }
	const handleDateChange = (val) => {
        if(val) {
            setFormValues({
                ...formValues, 
                timeStart: dateObjToDate(val.startDate),
                timeEnd: dateObjToDate(val.endDate)
            });
        }
        
	}
	const handleDetailsChange = (event) => {
        updateInputStateHook(setFormValues, formValues, event);
    }
	const handleChange = (event) => {
	  	updateInputStateHook(setFormValues, formValues, event);
    }
    const handleRangeChange = (event, shouldToUpdate, toName) => {
        if(shouldToUpdate) {
            setFormValues({...formValues,  [event.target.id]: event.target.value, [toName]: formValues[toName] < event.target.value ? event.target.value : formValues[toName]});
        } else {
            setFormValues({...formValues,  [event.target.id]: event.target.value });
        }
    }
	const handleSelectChange = (val, e) => {
        setFormValues({...formValues, [e]: val});
    }
    const handleSelectInputChange = (el) => {
        if(searchedMerchant !== el) {
            setSearchedMerchant(el);
            let params = {
                limit: limit,
                offset: 0,
                select: true
            };
            if(el !== '') {
                params.name = el;
            }
            dispatch(getMerchantsPaginated(params));
        }

    }
	const handleSubmit = (event) => {
        handleSubmitHook({
            event: event,
            funct: sendRequest,
            args: [limit, formValues],
            setFormDisabled: setFormDisabled,
            hasPagination: true
        });
    }
    const loadMore = () => {
        let before = items[items.length - 1]?.createdTime;
        if(before) {
            sendRequest(limit, formValues, before);
            setLoadMoreDisabled(true);
        }
    }
    const handleStateChange = (val) => {
        setFormValues({...formValues, status: val});
    }
    const onSend = ({amount, currency, transferCurrency, message, notificationUrl, merchantTransactionId, confirmedRedirect, expiredRedirect, invalidRedirect, unconfirmedRedirect, defineRedirects}) => {
        dispatch(postPayment({
            amount: amount,
            currency: currency,
            transferCurrency: transferCurrency,
            message: message,
            notificationUrl: notificationUrl,
            merchantTransactionId: merchantTransactionId,
            confirmedRedirect: confirmedRedirect,
            expiredRedirect: expiredRedirect,
            invalidRedirect: invalidRedirect,
            unconfirmedRedirect: unconfirmedRedirect,
            defineRedirects: defineRedirects
        }));
        setCurrencyPayment(currency);
    }
    const switchMerchantFunc = (el) => {
        dispatch(switchMerchant(el));
        removeStorage(el, account);
        history('/');
        window.location.reload();
    }
    const handleResendNotification = (transactionId, type) => {
        if(type === 'PAYMENT') {
            dispatch(resendPaymentNotification(transactionId));
        }
        if(type === 'PAYOUT') {
            dispatch(resendPayoutNotification(transactionId));
        }
        
    }
    const resetFilters = () => {
        setFormValues({...formValues,
            details: '',
            paymentId: '',
            payoutId: '',
            endDateFrom: '',
            timeStart: '',
            timeEnd: '',
            transactionId: '',
            selectClient:  '',
            status: [],
            amountFrom: '',
            amountTo: '',
            currency: '',
            transactionType: ''
        });
        setReloadRangePicker(true);
        setInitialDataLoaded(true);
    }
    return (
        <LayoutContainer active='explorer'>
            {isUnassignedMerchant ? (
                <div>
                    <h2>Available accounts</h2>
                    <p>Please choose which account You want to use:</p>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='widget card'>
                                <div className='card-block'>
                                    <div className='row'>
                                        {account.context.availableMerchants &&
                                            <ul className="list-unstyled list-link list-switch font-size-16 no-pdd-btm pdd-left-15">
                                                {account.context.availableMerchants.map(function(val,i){
                                                    return (
                                                        <li key={i}>
                                                            <a href='javascript:void(0);' onClick={() => switchMerchantFunc(val.id)}>
                                                                <i className='ti-user pdd-right-10'></i>
                                                                <span>{val.name}</span>
                                                            </a>
                                                        </li>
                                                    )
                                                })}
                                             </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {transactionsHistory === 'error' ? (
                        <LoadingError />
                    ) : (
                        <div>
                            <Breadcrumbs dataPath={{0: ['Home', '/'], 1: ['Transactions explorer', '']}}/>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2 className='mrg-btm-15 pull-left'>Transactions explorer</h2>
                                    {(isMerchantAdmin || isMerchantUser) &&
                                        <button className='btn btn-primary pull-right btn-long mrg-btm-12' onClick={showPaymentModalFunc} >New payment <i className='fa fa-plus-circle'></i></button>
                                    }
                                </div>
                            </div> 
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='search-box'>
                                        {initialDataLoaded &&
                                            <Form id='paymentFilterForm' onSubmit={handleSubmit}>
                                                <div className='row'>    
                                                    {reloadRangePicker !== true &&
                                                        <DateRangePicker
                                                            now={now} 
                                                            thisId='transactionDateRange' 
                                                            additionalPdd={true} 
                                                            classNames='col-md-3' 
                                                            explorer={true}
                                                            start={formValues.timeStart} 
                                                            end={formValues.timeEnd} 
                                                            onChange={handleDateChange} 
                                                            clearDateOption={true}
                                                        /> 
                                                    }    
                                                    {reloadType !== true &&                
                                                        <div className='col-md-3'>
                                                            <label htmlFor="transactionType">Transaction type: </label>
                                                            <Selectize
                                                                thisId='transactionType'
                                                                selected={formValues.transactionType}
                                                                dataOptions={[{label: 'All', value: ''}, {label: 'PAYMENT', value: 'PAYMENT', icon: '', iconColor: 'rgb(4, 0, 102)'}, {label: 'PAYOUT', value: 'PAYOUT', icon: '', iconColor: 'rgb(3, 143, 228)'}, {label: 'CONVERSION', value: 'CONVERSION', icon: '', iconColor: 'rgb(46, 205, 153)'}, {label: 'ADJUSTMENT', value: 'BALANCE_ADJUSTMENT', icon: '', iconColor: '#ffc107'}]}
                                                                isAsync={true}
                                                                isSearchable={true}
                                                                style={{fontFamily: 'FontAwesome'}}
                                                                onChange={handleSelectChange}
                                                            />
                                                        </div>
                                                    }
                                                    {reloadMerchant !== true && isAdmin &&
                                                        <>
                                                            {!_.isEmpty(merchantsNamesPaginated) &&
                                                                <div className='col-md-3'>
                                                                    <div>
                                                                        <label htmlFor='selectClient'>Merchant:</label>
                                                                        <Selectize
                                                                            thisId='selectClient'
                                                                            handleInputChange={handleSelectInputChange}
                                                                            selected={selectClientVal}
                                                                            dataOptions={merchantsNamesPaginated}
                                                                            isAsync={true}
                                                                            isSearchable={true}
                                                                            onChange={handleSelectChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    {isMerchant ? (
                                                        <div className='col-md-3'>
                                                            <label htmlFor="details">Search in details: <Tooltip id='searchInDetailsTooltip' placement='top' text='Search by Address, TX ID or Transaction ID' /></label>
                                                            <Input type='text' className='form-control' id='details' validations={[]} name='details' value={formValues.details} data-optional='true' data-partial-possible={true}  onChange={handleDetailsChange} />
                                                        </div>
                                                    ) : (<></>)}
                                                    <div className='col-md-3'>
                                                        {formDisabled === true ? (
                                                            <>
                                                                <button type='submit' className={collapsed ? 'mrg-top-28 btn btn-primary btn-full disabled no-mrg-btm' : 'mrg-top--5 btn btn-primary btn-full disabled no-mrg-btm'}  disabled>
                                                                    <span>Search</span>
                                                                </button>
                                                                <button className='btn btn-sm btn-default text-center btn-full mrg-btm-15' onClick={() => setCollapsed(!collapsed)}>{collapsed ? 'Hide filters' : 'Show filters'}</button>
                                                                <button style={{position: 'absolute', right: '-10px', bottom: '-19px'}} className='btn btn-sm text-center text-danger btn-full no-mrg-btm' onClick={resetFilters}>Clear filters <i className='fa fa-times-circle'></i></button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Button type='submit' className={collapsed ? 'mrg-top-28 btn btn-primary btn-full no-mrg-btm' : ' mrg-top--5 btn btn-primary btn-full no-mrg-btm'} >Search</Button>
                                                                <button className='btn btn-sm btn-default text-center btn-full mrg-btm-15' onClick={() => setCollapsed(!collapsed)}>{collapsed ? 'Hide filters' : 'Show filters'}</button>
                                                                <button style={{position: 'absolute', right: '-10px', bottom: '-19px'}} className='btn btn-sm text-center text-danger btn-full no-mrg-btm' onClick={resetFilters}>Clear filters <i className='fa fa-times-circle'></i></button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                 <div className="row">
                                                {collapsed &&
                                                    <>
                                                       
                                                            <div className='col-md-3' style={{marginTop: '-35px'}}>
                                                                <label htmlFor="coinAmountFrom">Amount: </label>
                                                                <RangeContainer>
                                                                    <Input type='text' className='form-control' id='amountFrom' validations={[]} name='amountFrom' value={formValues.amountFrom} data-optional='true' data-partial-possible={true}  onChange={(e) => handleRangeChange(e, true, 'amountTo')} />
                                                                    <span className='separator'>-</span>
                                                                    <Input type='text' className='form-control' id='amountTo' validations={[]} name='amountTo' value={formValues.amountTo} data-optional='true' data-partial-possible={true}  onChange={(e) => handleRangeChange(e)} />
                                                                </RangeContainer>
                                                            </div>
                                                            {reloadCurrency !== true &&
                                                                <>
                                                                    {currencies.length ? (
                                                                        <div className='col-md-3' style={{marginTop: '-35px'}}>
                                                                            <label htmlFor="currency">Currency: </label>
                                                                            <Selectize
                                                                                thisId='currency'
                                                                                selected={formValues.currency}
                                                                                dataOptions={[{label: 'All', value: ''}].concat(currencies.map(curr => ({label: curr.code, value: curr.code})))}
                                                                                isAsync={true}
                                                                                isSearchable={true}
                                                                                onChange={handleSelectChange}
                                                                            />
                                                                        </div>
                                                                    ) : (<></>)}
                                                                </>
                                                            }
                                                            {isAdmin ? (
                                                                <div className='col-md-3' style={{marginTop: '-35px'}}>
                                                                    <label htmlFor="details">Search in details: <Tooltip id='searchInDetailsTooltip' placement='top' text='Search by Address, TX ID or Transaction ID' /></label>
                                                                    <Input type='text' className='form-control' id='details' validations={[]} name='details' value={formValues.details} data-optional='true' data-partial-possible={true}  onChange={handleDetailsChange} />
                                                                </div>
                                                            ) : (<></>)}
                                                        </>
                                                    }   
                                                    </div>
                                                
                                            </Form>
                                        }
                                    </div>
                                    <div className='card no-border-top mrg-top-20'>
                                        <div>
                                            <TransactionsTable
                                                isAdmin={isAdmin}
                                                limit={limit}
                                                modalClosed={modalClosed}
                                                modalProfitClosed={modalProfitClosed}
                                                modalConfirmClosed={modalConfirmClosed}
                                                noMargin={true}
                                                transactionsGetHistory={transactionsGetHistory}
                                                transactionsHistory={transactionsHistory}
                                                transactionId={formValues.transactionId}
                                                profitDetails={profitDetails}
                                                isPayout={formValues.isPayout}
                                                showModal={showModalFunc}
                                                showConfirmModal={showConfirmModalFunc}
                                                showModalStatus={showModal}
                                                showConfirmModalStatus={showConfirmModal}
                                                showProfitModal={showProfitModalFunc}
                                                showProfitModalStatus={showProfitModal}
                                                tableHeight={tableHeight}
                                                handleResendNotification={handleResendNotification}
                                            />
                                        </div>
                                        {transactionsHistory.data?.data?.items.length ? (
                                            <div className='pagination-content clearfix mrg-btm-30'>
                                                <div className='col-md-12 text-center'>
                                                    <p className='pull-left pages-summary absolute'>{allResults ? (<>Page {page + 1}/{pages} - {allResults} results</>) : (<></>)}</p>
                                                    {items && items[(page + 1) * limit - 1] ? (
                                                        <button className='btn btn-default mrg-top-10 no-mrg-btm' onClick={loadMore} disabled={loadMoreDisabled} style={{minWidth: 162}}>{loadMoreDisabled ? <img src={loader} alt='Loading...' width={120} /> : 'Load more'}</button>
                                                    ) : (
                                                        <div style={{height: 47}}></div>
                                                    )}
                                                    
                                                </div>
                                            </div>
                                        ) : (<></>)}
                                    </div>
                                </div>
                            </div>
                            {showPaymentModal === true &&
                                <NewPaymentModalContainer showOnInit={true} onModalClosed={modalPaymentClosed} formSent={onSend} />
                            }
                        </div>
                    )}
                </div>
            )}
        </LayoutContainer>

        )
	
}

TransactionsExplorer.propTypes = {
    history: PropTypes.object,
    location:  PropTypes.object,
    match: PropTypes.object
}


export default TransactionsExplorer;

import React, { useContext, useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardTabsContext } from 'contexts/dashboard.tabs.context'
import { postPayment, resetPayment } from 'actions/payment'
import { postPayout, resetPayout, confirmPayout, resetConfirmedPayout, cancelPayout, resetCancelPayout } from 'actions/payout'
import { postBalanceAdjustment, resetBalanceAdjustment } from 'actions/balanceAdjustment'
import { getLiveBalance, resetLiveBalance } from 'actions/liveBalance'
import { AdditionalDataContainer, AdditionalData } from 'components/ui_elements/simple_data_box'
import Tab from 'components/ui_elements/tab'
import DashboardChart from './dashboard_chart'
import NewPayoutModal from 'components/new_payout/new_payout_modal'
import NewBalanceAdjustmentModal from 'components/new_balance_adjustment/new_balance_adjustment_modal'
import NewPaymentModalContainer from 'components/new_payment/new_payment_modal_container'
import { initAlert } from 'helpers/alert_helper'
import { camelCaseToWords } from 'helpers/class_helper'
import { totalCommaPrecision } from 'helpers/number_helper'
import { dateToIso, dateToIsoFull, dateToName } from 'helpers/date_helper'

const LegendBoxElem = styled.div`
    width: 30px;
    height: 10px;
    background-color: #fafafa;
    border: 1px solid #dddddd;
    display: inline-block;
    margin-right: 5px;
`

export const colorsList = {
    backgroundColor: [
        'rgba(4, 0, 102, 0.2)',
        'rgba(75, 210, 164, 0.2)',
        'rgba(3, 143, 228, 0.2)',
        'rgba(39, 170, 206, 0.2)',
        'rgba(17, 99, 148, 0.2)',
        'rgba(146, 143, 218, 0.2)',
        'rgba(240, 240, 243, 0.2)',
        'rgba(4, 0, 102, 0.4)',
        'rgba(75, 210, 164, 0.4)',
        'rgba(3, 143, 228, 0.4)',
        'rgba(39, 170, 206, 0.4)',
        'rgba(17, 99, 148, 0.4)',
        'rgba(146, 143, 218, 0.4)',
        'rgba(240, 240, 243, 0.4)',
        'rgba(4, 0, 102, 0.6)',
        'rgba(75, 210, 164, 0.6)',
        'rgba(3, 143, 228, 0.6)',
        'rgba(39, 170, 206, 0.6)',
        'rgba(17, 99, 148, 0.6)',
        'rgba(146, 143, 218, 0.6)',
        'rgba(240, 240, 243, 0.6)',
      ],
      borderColor: [
        'rgba(4, 0, 102, 1)',
        'rgba(75, 210, 164, 1)',
        'rgba(3, 143, 228, 1)',
        'rgba(39, 170, 206, 1)',
        'rgba(17, 99, 148, 1)',
        'rgba(146, 143, 218, 1)',
        'rgba(187, 187, 193, 1)',
        'rgba(4, 0, 102, 1)',
        'rgba(75, 210, 164, 1)',
        'rgba(3, 143, 228, 1)',
        'rgba(39, 170, 206, 1)',
        'rgba(17, 99, 148, 1)',
        'rgba(146, 143, 218, 1)',
        'rgba(187, 187, 193, 1)',
        'rgba(4, 0, 102, 1)',
        'rgba(75, 210, 164, 1)',
        'rgba(3, 143, 228, 1)',
        'rgba(39, 170, 206, 1)',
        'rgba(17, 99, 148, 1)',
        'rgba(146, 143, 218, 1)',
        'rgba(187, 187, 193, 1)',
      ]
}


const LegendBox = ({count}) => {
    return <LegendBoxElem style={{borderColor: colorsList.borderColor[count], backgroundColor: colorsList.backgroundColor[count]}} />
}

const DashboardTab = ({featuredTransactions, showModalFunc, timeScope, type, typeSingular, sendRequest, formValues, activeTab}) => {
    const dispatch = useDispatch();
    const currencies = useSelector(state => state.currencies);
    const newPayment = useSelector(state => state.newPayment);
    const newPayout = useSelector(state => state.newPayout);
    const newBalanceAdjustment = useSelector(state => state.newBalanceAdjustment);
    const confirmedPayout = useSelector(state => state.confirmedPayout);
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchant = useSelector(state => state.isMerchant);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    const [showBalanceAdjustment, setShowBalanceAdjustment] = useState(false);
    const [currencyPayment, setCurrencyPayment] = useState();
    const [currencyPayout, setCurrencyPayout] = useState();
    const [confirmationSending, setConfirmationSending] = useState();
    const [additionalDataValues, setAdditionalDataValues] = useState([]);
    useEffect(() => {
        if(featuredTransactions) {
            let addData = [];
            featuredTransactions.map((curr,i) => {
                addData.push({
                    merchantId: curr.merchantId,
                    labelText: curr.merchantName,
                    value: curr.euroAmount,  
                    currency: "EUR",
                    last: i == featuredTransactions.length -1
                })
                return;
            });
            addData.sort((a,b) => b.value - a.value);
            setAdditionalDataValues(addData);
        }

    }, [featuredTransactions]);
    useEffect(() => {
        if(!_.isEmpty(newPayment) && newPayment !== undefined && currencyPayment) {
            if(newPayment.type === 'error') {
                initAlert({
                    type: 'error',
                    title: 'Error',
                    html: newPayment.response
                });
                dispatch(resetPayment());
                return;
            }
            if(newPayment.status === 200) {
                initAlert({
                    type: 'success',
                    title: 'Payment created',
                    html:
                        'You have successfully created new payment for: ' +
                        '<br />' +
                        '<br />' +
                        '<h4 class="mrg-btm-15"><b>' + totalCommaPrecision({toFormat: newPayment.data.transferAmount, currCode: newPayment.data.transferCurrency, currencies}) + ' ' + newPayment.data.transferCurrency + '</b></h4>' +
                        'To see this payment go to Payment Page' +
                        '<br />' +
                        '<a class="btn btn-default mrg-top-30 mrg-btm-30 btn-long" target="_blank" rel="noopener noreferrer" href="' + newPayment.data.paymentPageUrl + '">Payment Page</a>' +
                        '<br />' +
                        'or click OK to continue',
                    confirmText: 'OK',
                    classNames: 'long'
                });
                dispatch(resetPayment());
                setCurrencyPayment(false);
            }
            sendRequest();
            setCurrencyPayment(false);
        }
    }, [newPayment]);
    useEffect(() => {
        if(!_.isEmpty(newPayout) && newPayout !== undefined && currencyPayout) {
            if(newPayout.type === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: newPayout.response
                });
                dispatch(resetPayout());
                return;
            }
            if(newPayout.data.status === 'SENT' || newPayout.data.status === 'QUEUED') {
                handleSuccess(newPayout);
                dispatch(resetConfirmedPayout());
                setCurrencyPayout(false);
                setConfirmationSending(false);
                return;
            } 
            let newPayoutData = newPayout.data;
            dispatch(resetPayout());
            sendRequest();
            newPayoutSubmit({
                transferAmount: newPayoutData.transferAmount, 
                priceAmount: newPayoutData.priceAmount, 
                address: newPayoutData.address, 
                transferCurrency: newPayoutData.transferCurrency,
                priceCurrency: newPayoutData.priceCurrency, 
                id: newPayoutData.payoutId, 
                expirationTime: newPayoutData.expirationTime
            });
            setCurrencyPayout(false);
        }
    }, [newPayout]);
    useEffect(() => {
        if(type === 'balance') {
            if(newBalanceAdjustment !== '' && newBalanceAdjustment !== undefined) {
                if(newBalanceAdjustment.type === 'error') {
                    initAlert({
                        type: 'error', 
                        title: 'Error', 
                        html: newBalanceAdjustment.response
                    });
                    dispatch(resetBalanceAdjustment());
                    return;
                }
                
                if(newBalanceAdjustment === 200) {
                    initAlert({
                        type: 'success', 
                        title: 'Balance adjustment successfull', 
                        html: 
                            'The adjustment was executed successfully.'
                    });
                    dispatch(resetBalanceAdjustment());
                    dispatch(getLiveBalance());
                } 
                
            }
        }
    }, [newBalanceAdjustment]);
    useEffect(() => {
        if(!_.isEmpty(confirmedPayout) && confirmedPayout !== undefined && confirmationSending) {
            if(confirmedPayout.type === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: confirmedPayout.response
                });
                dispatch(resetPayout());     
                sendRequest();
                setConfirmationSending(false);
                return;
            }
            handleSuccess(confirmedPayout);
            dispatch(resetConfirmedPayout());
            setConfirmationSending(false);
        }

    }, [confirmedPayout]);
    const modalPaymentClosed = () => {
        setShowPaymentModal(false);
    }
    const modalClosed = () => {
        setShowPayoutModal(false);
    }
    const modalAdjustmentClosed = () => {
        setShowBalanceAdjustment(false)
    }
    const onSend = ({amount, currency, transferCurrency, message, notificationUrl, merchantTransactionId, confirmedRedirect, expiredRedirect, invalidRedirect, unconfirmedRedirect, defineRedirects}) => {
        dispatch(postPayment({
            amount: amount,
            currency: currency,
            transferCurrency: transferCurrency,
            message: message,
            notificationUrl: notificationUrl,
            merchantTransactionId: merchantTransactionId,
            confirmedRedirect: confirmedRedirect,
            expiredRedirect: expiredRedirect,
            invalidRedirect: invalidRedirect,
            unconfirmedRedirect: unconfirmedRedirect,
            defineRedirects: defineRedirects
        }));
        setCurrencyPayment(currency);
    }
    const onSendPayout = ({coin, fiat, address, currency, payCurrency, notificationUrl, comment, autoApprove, skipTrading}) => {
        const postData = {
            coin: coin, 
            fiat: fiat, 
            currency: currency, 
            payCurrency: payCurrency, 
            notificationUrl: notificationUrl, 
            comment: comment, 
            autoApprove: autoApprove, 
            skipTrading: skipTrading
        }
        if(address && address !== '') {
            postData.address = address;
        }
        dispatch(postPayout({...postData}));
        setCurrencyPayout(currency);
    }
    const onSendAdjustment = ({merchantId, amount, currency, adminComment, merchantComment, asOfDate, idempotencyKey}) => {
        const postData = {
            'merchantId': merchantId,
            'amount': amount,
            'currency': currency,
            'adminComment': adminComment,
            'merchantComment': merchantComment,
            'asOfDate': asOfDate,
            'idempotencyKey': idempotencyKey
        }
        dispatch(postBalanceAdjustment({...postData}));
    }
    const newPayoutSubmit = ({transferAmount, priceAmount, address, transferCurrency, priceCurrency, id, expirationTime}) => {
        let timeNow = dateToIsoFull(new Date());
        let payoutMessage = 
            'Your payout request have been calculated for' +
            '<br />' +
            '<div class="card mrg-top-15 no-mrg-btm">' +
                '<div class="card-block card-block-small">' +
                    '<p class="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                        '<span class="text-dark font-size-18">' +
                            '<b>' + transferAmount + ' ' + transferCurrency +'</b>' +
                        '</span>' +
                    '</p>' +
                '</div>' +
            '</div>' +
            '<br />' +
            'and will cost' +
            '<br />' +
            '<div class="card mrg-top-15 no-mrg-btm">' +
                '<div class="card-block card-block-small pdd-left-15 pdd-right-15">' +
                    '<p class="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                        '<span class="text-dark font-size-18">' +
                            '<b>' + priceAmount + ' ' + priceCurrency + '</b>' +
                        '</span>' +
                    '</p>' +
                '</div>' +
            '</div>' +
            '<br />';

        if(address !== null) {
            payoutMessage += 
                'sending address is:' +
                '<br />' +
                '<div class="card mrg-top-15 no-mrg-btm">' +
                    '<div class="card-block card-block-small pdd-left-15 pdd-right-15">' +
                        '<p class="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                            '<span class="text-dark font-size-18">' +
                                '<b>' + address + '</b>' +
                            '</span>' +
                        '</p>' +
                    '</div>' +
                '</div>' +
                '<br />' +
                '<br />' +
                'This exchange rate is valid for <b id="timeLeft"></b>' +
                '<br />' +
                'Do you want to continue?' +
                '<br />' +
                '<div id="myProgress" class="mrg-top-30 mrg-btm-30 hide">' +
                    '<div id="myBar">' +
                    '</div>' +
                '</div>';
        } else {
            payoutMessage += 
                '<br />' +
                'This exchange rate is valid for <b id="timeLeft"></b>' +
                '<br />' +
                'Do you want to continue?' +
                '<br />' +
                '<div id="myProgress" class="mrg-top-30 mrg-btm-30 hide">' +
                    '<div id="myBar">' +
                    '</div>' +
                '</div>';
        }
        initAlert({
            type: 'info', 
            title: 'Payout calculated', 
            html: payoutMessage, 
            confirmText: 'Yes, send ' + transferCurrency,  
            cancelText: 'Exit',
            onSuccess: () => sendCoin(id), 
            onDismiss: onCanceled
        });
        timerDisplay(expirationTime, timeNow);
    }
    const timerDisplay = (expirationTime, timeNow) => {
        let date1 = moment(timeNow, 'YYYY-MM-DD HH:mm:ss');
        let date2 = moment(expirationTime, 'YYYY-MM-DD HH:mm:ss');
        let diff = date2.diff(date1, 'seconds');
        let elem = document.getElementById('myBar'); 
        let text = document.getElementById('timeLeft');
        let hour = 3600;
        let width = 100;
        let endText = '';
        let id = setInterval(frame, 1000);
        let started = true;
        function frame() {
            if (width <= 0) {
                swal.close();
                sendRequest();
                clearInterval(id);
            } else {
                diff--;
                width = diff / hour * 100;
                date2 = moment(date2).subtract(1, 'seconds');
                let newDate = moment.utc(moment(date2).diff(moment(date1)));
                endText = newDate.format('HH') === '00' ? newDate.format('mm:ss') + ' min' : newDate.format('HH:mm:ss') + ' min';
                text.innerHTML = endText;
                elem.style.width = width + '%';
                if (started === true) {
                  document.getElementById('myProgress').classList.remove('hide');
                  started = false;
                }
                if(width < 50) {
                  elem.classList.add('warning');
                } 
                if(width < 20) {
                  elem.classList.remove('warning')
                  elem.classList.add('danger');
                }
            }
        }
    }
    const sendCoin = (id) => {
        dispatch(confirmPayout(id));
        setConfirmationSending(true);
    }
    const onCanceled = () => {
        return
    }
    const handleSuccess = (val) => {
        let el = val.data;
        initAlert({
            type: 'success', 
            title: 'Started processing', 
            html: 
                'Your payout is now being processed' +
                '<br/>' +
                '<br/>' + 
                '<div class="col-md-12">' +
                    '<div class="card no-mrg-top">' +
                        '<div class="card-block card-block-success">' +
                            '<div>' +
                                '<p class="clearfix mrg-btm-15">' +
                                    '<span class="pull-left font-size-14">Total ' + el.transferCurrency + ' to send:</span>' +
                                    '<span class="pull-right text-dark font-size-18">' + el.transferAmount + ' ' + el.transferCurrency + '</span>' + 
                                '</p>' +
                            '</div>' +
                            '<div>' +
                                '<p class="no-mrg-btm clearfix">' +
                                    '<span class="pull-left">Total price: </span>' +
                                    '<span class="pull-right text-dark font-size-18"><b>' + el.priceAmount + ' ' + el.priceCurrency + '</b></span>' +
                                '</p>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<br/>' +
                'After processing is completed and payout is sent status in the system will change from <b>QUEUED</b> to <b>SENT</b>.'
        });
        dispatch(resetPayout());
        sendRequest();
    }
    return (
        <Tab name={type} labelledby={type + '-tab'} active={activeTab}>
            <div className="row">
                {additionalDataValues.length ? (
                    <>
                        <div className="col-md-6 offset-md-1">
                            {activeTab === type &&
                                <DashboardChart type={type} additionalDataValues={additionalDataValues} />
                            }
                        </div>
                        <div className="col-md-5">
                            {(type === 'payments' && isMerchant) || (type === 'payouts') && <button className='btn btn-primary btn-sm pull-right mrg-top-20 mrg-right-15 mrg-btm-20' onClick={() => type === 'payments' ? setShowPaymentModal(true) : setShowPayoutModal(true)}>New {typeSingular}</button>}
                            {(type === 'balance' && isAdmin) && <button className='btn btn-primary btn-sm pull-right mrg-top-20 mrg-right-15 mrg-btm-20' onClick={() => setShowBalanceAdjustment(true)}>Create adjustment</button>}
                            <AdditionalDataContainer className='pdd-btm-20 pdd-right-30 clearfix simple-data-inner'>
                                <h3 style={{position: 'absolute', top: 25}}>{type == 'balance' ? 'Live balances' : <>{camelCaseToWords(type)} {timeScope.view ? timeScope.view === 'custom' ? ' between ' + formValues.dateFrom + '-' + formValues.dateTo : camelCaseToWords(timeScope.view) : ''}</>}:</h3>
                                {additionalDataValues ? (
                                        <div style={{marginTop: 100}}>
                                            {additionalDataValues.map((data, i) => (
                                                <AdditionalData className={data.last ? 'last' : ''}>
                                                    <label className='no-mrg-btm'><LegendBox count={i} /> {data.labelText}</label>
                                                    <h5 className='value'>{totalCommaPrecision({toFormat: data.value, currCode: data.currency, currencies: currencies})} {data.currency}</h5>
                                                </AdditionalData>
                                            ))}
                                        </div>
                                        
                                    ) : (
                                    <></>
                                )}
                            </AdditionalDataContainer>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-md-12 pdd-left-50 mrg-top-50 mrg-btm-50">
                            <h3>No {camelCaseToWords(type)}{timeScope.view === 'today' ? ' ' : timeScope.view === 'currentMonth' || timeScope.view === 'currentYear' ? ' in ' : ' between '}{timeScope.view === 'custom' ? formValues.dateFrom + '-' + formValues.dateTo : timeScope.view ? camelCaseToWords(timeScope.view) : ''}
                            {(type === 'payments' && isMerchant) || (type === 'payouts') ? <button style={{position: 'absolute', top: '-30px', right: 15}} className='btn btn-primary btn-sm pull-right mrg-top-20 mrg-right-20 mrg-btm-20' onClick={() => type === 'payments' ? setShowPaymentModal(true) : setShowPayoutModal(true)}>New {typeSingular}</button> : <></>}</h3>
                        </div>
                    </>
                )}
            </div>
            {showPaymentModal === true &&
                <NewPaymentModalContainer showOnInit={true} onModalClosed={modalPaymentClosed} formSent={onSend} />
            }
            {showPayoutModal === true &&
                <NewPayoutModal showOnInit={true} onModalClosed={modalClosed} formSent={onSendPayout} />
            }
            {showBalanceAdjustment === true &&
                <NewBalanceAdjustmentModal showOnInit={true} onModalClosed={modalAdjustmentClosed} formSent={onSendAdjustment} />
            }
        </Tab>
    )
}

export default DashboardTab;
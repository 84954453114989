import React, { useEffect, useState } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useNavigate,
    useLocation
} from 'react-router-dom'
import _ from 'lodash'
import ReactGA from 'react-ga'
import Login from './components/pages/login'
import Dashboard from './components/pages/dashboard'
import TransactionsExplorer from './components/pages/transactions_explorer'
import Callback from './components/pages/callback'
import Merchants from './components/pages/merchants'
import Merchant from './components/pages/merchant'
import Users from './components/pages/users'
import Currencies from './components/pages/currencies'
import TermsAndConditions from './components/pages/terms_and_conditions'
import Error403 from 'components/pages/403'
import NotFound from './components/pages/notfound'
import { useDispatch, useSelector } from 'react-redux'
import {getAccount} from './actions/account'
import {getMerchant} from './actions/merchant'
import {parseJwt} from './helpers/helpers'
import {secureStorage} from './helpers/secure_storage_helper'
import {encodeBasicUrl} from './helpers/helpers'
import ScrollToTop from "./helpers/scroll_to_top"
import { ENV } from "index"
import { refreshToken } from 'actions/getToken'

export const TABLE_ROW_HEIGHT = 72
export const ADMIN_PREFIX = 'admin'

const CatchAccessErrors = () => {
    const history = useNavigate()
    const forbidden = useSelector(state => state.forbidden)
    useEffect(() => {
        if(forbidden === true) {
            history('/403');
        }
    }, [forbidden])
    return
}



const RequireAuth = ({ parsedToken, validFunction, validAdmin, isUserAdmin, isValidTermsAndConditions, children }) => {
    const location = useLocation()
    if(parsedToken?.azp !== ENV.KEYCLOAK_CLIENT_FRONT && !isUserAdmin()) {
        localStorage.removeItem('accessToken')
        secureStorage.removeItem('tokenExpires')
        secureStorage.removeItem('userEmail')
        secureStorage.removeItem('role')
        secureStorage.removeItem('merchantId')
        secureStorage.removeItem('userRole')
        secureStorage.removeItem('approvedTerms')
        secureStorage.removeItem('refreshToken')
    }
    if(secureStorage.getItem('userRole')){
        secureStorage.removeItem('userRole')
    }
    return (
        validFunction() ? (
            validAdmin() ? (
                <Navigate to={{
                    pathname: '/' + ADMIN_PREFIX
                }}/>
            ) : (
            isUserAdmin() ? (
                <>{children}</>
            ) : (
                isValidTermsAndConditions() ? (
                         <>{children}</>
                    ) : (
                        <Navigate to={{ 
                            pathname: '/terms-and-conditions',
                        }}
                        state={{prevPath: location.pathname + location.search}}
                        />
                    )
            )
            )
        ) : (
            <Navigate to={{
                pathname: '/login'
            }}/>
        )
    )
}

const RequireTerms = ({ parsedToken, validFunction, validAdmin, isUserAdmin, isValidTermsAndConditions, children }) => {
    const location = useLocation()
    if(parsedToken?.azp !== ENV.KEYCLOAK_CLIENT_FRONT && !isUserAdmin()) {
        localStorage.removeItem('accessToken')
        secureStorage.removeItem('tokenExpires')
        secureStorage.removeItem('userEmail')
        secureStorage.removeItem('role')
        secureStorage.removeItem('merchantId')
        secureStorage.removeItem('userRole')
        secureStorage.removeItem('approvedTerms')
        secureStorage.removeItem('refreshToken')
    }
    if(secureStorage.getItem('userRole')){
        secureStorage.removeItem('userRole')
    }
    return (
        validFunction() ? (
            validAdmin() || isUserAdmin() ? (
                <Navigate to={{
                    pathname: '/'
                }}/>
            ) : (
                isValidTermsAndConditions() ? (
                        <Navigate to={location.state?.prevPath ? location.state.prevPath : '/'}
                        state={{}}
                        />
                    ) : (
                        <>{children}</>
                    )
            )
        ) : (
            <Navigate to={{
                pathname: '/login'
            }}/>
        )
    )
}


const RequireAdminAuth = ({ parsedToken, validFunction, validAdmin, children }) => {
    if(parsedToken?.azp !== ENV.KEYCLOAK_CLIENT_BACK) {
        localStorage.removeItem('accessToken')
        secureStorage.removeItem('tokenExpires')
        secureStorage.removeItem('userEmail')
        secureStorage.removeItem('role')
        secureStorage.removeItem('merchantId')
        secureStorage.removeItem('userRole')
        secureStorage.removeItem('approvedTerms')
        secureStorage.removeItem('refreshToken')
    } 
    if(secureStorage.getItem('merchantId')){
        secureStorage.removeItem('merchantId')
    }
    if(secureStorage.getItem('userRole')){
        secureStorage.removeItem('userRole')
    }
    return (
      validFunction() ? (
            <>{children}</>
        ) : (
            <Navigate to={{
                pathname: '/' + ADMIN_PREFIX + '/login'
            }} replace/>
        )
    )
}

const RequireContextAuth = ({ parsedToken, validFunction, validAdmin, isUserAdmin, children }) => {
    if(parsedToken?.azp !== ENV.KEYCLOAK_CLIENT_BACK) {
        localStorage.removeItem('accessToken')
        secureStorage.removeItem('tokenExpires')
        secureStorage.removeItem('userEmail')
        secureStorage.removeItem('role')
        secureStorage.removeItem('merchantId')
        secureStorage.removeItem('userRole')
        secureStorage.removeItem('approvedTerms')
        secureStorage.removeItem('refreshToken')
    }
    return (
        validFunction() ? (
            isUserAdmin() && !validAdmin() ? (
                <>{children}</>
            ) : (
                <Navigate to={{ 
                    pathname: '/' + ADMIN_PREFIX
                }}/>
            )
        ) : (
            <Navigate to={{
                pathname: '/' + ADMIN_PREFIX + '/login'
            }} replace/>
        )
    )
  }



const App = () => {
    let _rollbarConfig = {}
    let isRollbarSet = true
    if(ENV.APP_MODE === 'DEV' || ENV.APP_MODE === 'PRODUCTION' || ENV.APP_MODE === 'DEMO') {
        _rollbarConfig = {
          accessToken: ENV.ROLLBAR_TOKEN,
          addErrorContext: true,
          captureUncaught: true,
          captureUnhandledRejections: true,
          enabled: (ENV.APP_MODE !== ''),
          payload: {
            environment: ENV.APP_MODE
          },
          sendConfig: true
        }
    }
    isRollbarSet = false
    const dispatch = useDispatch()
    const [role, setRole] = useState(secureStorage.getItem('role'))
    const [context, setContext] = useState(false)
    const [rollbar, setRollbarConfig] = useState(_rollbarConfig)
    const [setRollbar, setRollbarState] = useState(isRollbarSet)
    const [selfRequested, setSelfRequested] = useState(false)
    const account = useSelector(state => state.account)
    const currentView = useSelector(state => state.currentView)
    const selfMerchant = useSelector(state => state.selfMerchant)
    const token = useSelector(state => state.token)
    const parsedToken = parseJwt(localStorage.getItem('accessToken'))
    
    useEffect(() => {
        if(_.isEmpty(account) && localStorage.getItem('accessToken') !== null) {
            dispatch(getAccount(localStorage.getItem('accessToken')))
        }
    }, []);
    useEffect(() => {
        if(selfRequested === false) {
            setSelfRequested(true)
            getSelf()
        }
    }, [selfRequested])
    useEffect(() => {
        if (ENV && ENV.GA_KEY) {
            ReactGA.initialize(ENV.GA_KEY);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [ENV])

    useEffect(() => {
        
        if(role === null && localStorage.getItem('role') !== null) {
            setRole(localStorage.getItem('role'))
        }
        if(setRollbar === false) {
            let rollbarConfig = rollbar
            rollbarConfig.payload = {
                person : {
                    contextRole: secureStorage.getItem('role'), 
                    role: secureStorage.getItem('userRole'),
                    email: secureStorage.getItem('userEmail')
                }, 
                view: currentView,
                environment: ENV.APP_MODE
            }
            if(secureStorage.getItem('merchantId')) {
                rollbarConfig.payload.merchant = {
                    merchantId: secureStorage.getItem('merchantId'),
                }
            }
            setRollbarState(true)
            setRollbarConfig(rollbarConfig)
        }
        if((!_.isEmpty(account) && account !== 'error')) {
            if(context === false) {
                secureStorage.setItem('role', account.context.viewType)
                setContext(true)
                setRole(account.user.roleType)
            }
        } 
            
    })
    useEffect(() => {
        if(!_.isEmpty(account) && account !== 'error') { 
            let merchId = secureStorage.getItem('merchantId')
            if(merchId && !isUserAdmin()) {
                let isMerchantAvailable = _.find(account.context.availableMerchants, function(merch) {
                    return merch.id === merchId
                });
                if(isMerchantAvailable === undefined) {
                    secureStorage.removeItem('merchantId')
                }
            }
            if(secureStorage.getItem('role') === null || secureStorage.getItem('role') === 'undefined') {
                if(account.user.role === 'ADMIN' && account.context.viewType !== 'MERCHANTADMIN') {
                    secureStorage.removeItem('role')
                    secureStorage.removeItem('merchantId')
                }
                
                secureStorage.setItem('userRole', account.user.role)
                secureStorage.setItem('userEmail', account.user.email)
                secureStorage.setItem('role', account.context.viewType)
                secureStorage.setItem('approvedTerms', account.user.approvedTermsConditions)
            }
        }
    }, [account])
    useEffect(() => {
        if(token.session) {
            const accessToken = token.session?.access_token
            if(accessToken && accessToken.error === undefined) { 
                localStorage.setItem('accessToken', accessToken)
                if(_.isEmpty(account)) {
                    dispatch(getAccount(accessToken))
                }
                let parsedTokenNew = parseJwt(accessToken)
                secureStorage.setItem('tokenExpires', parsedTokenNew.exp)
                secureStorage.setItem('refreshToken', token.session.refresh_token)
            }
            if(token.session === 'error') {
                storegeRemove()
                window.location.reload()
            }
        }
    }, [token])
    useEffect(() => {
        const interval = setInterval(() => {
            const dateNow = Date.now() / 1000
            const tokenExpires = secureStorage.getItem('tokenExpires')
            const timeLeft = tokenExpires - dateNow
            const adminPriviliges = secureStorage.getItem('role') === 'ADMIN' || secureStorage.getItem('userRole') === 'ADMIN'
            if (timeLeft < 90) {
                dispatch(refreshToken({
                    refreshToken: secureStorage.getItem('refreshToken'),
                    keycloakDomain: ENV.KEYCLOAK_DOMAIN,
                    client_id: adminPriviliges ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT,
                    is_backend:  adminPriviliges ? true : false
                }))
            }
        }, 60000)

        return () => clearInterval(interval) 
	}, [secureStorage.getItem('tokenExpires')])

    const storegeRemove = () => {
        localStorage.removeItem('accessToken')
        secureStorage.removeItem('tokenExpires')
        secureStorage.removeItem('userEmail')
        secureStorage.removeItem('role')
        secureStorage.removeItem('merchantId')
        secureStorage.removeItem('userRole')
        secureStorage.removeItem('approvedTerms')
        secureStorage.removeItem('refreshToken')
    }

    const getSelf = () => {
        if(selfRequested !== true && (_.isEmpty(selfMerchant)) && isMerchant() === true && secureStorage.getItem('merchantId') !== null) {
            dispatch(getMerchant(secureStorage.getItem('merchantId'), true))
            setSelfRequested(true)
        }
    }
    const isTokenValid = (type) => {
        let dateNow = Date.now() / 1000
        let isNotExpired = secureStorage.getItem('tokenExpires') > dateNow
        let typeClient = type === 'admin' ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT
        if(localStorage.getItem('accessToken') === null) { 
            return false 
        } else { 
            if(isNotExpired && parsedToken !== undefined && parsedToken.azp === typeClient) {
                return true
            } else {
                return false
            }
        } 
    }
    const isAdmin = () => {
        if(secureStorage.getItem('role') === 'ADMIN') {
            return true 
        } else { 
            return false
        } 
    }
    const isMerchant = () => {
        let role = secureStorage.getItem('role')
        if(role === 'MERCHANT' || role === 'MERCHANTADMIN' || role === 'MERCHANTVIEWER') { 
            return true 
        } else { 
            return false
        } 
    }
    const isValidTermsAndConditions = () => {
        if(account.user?.approvedTermsConditions === true) {
            return true
        } else { 
            return false
        }
    }
    const isUserAdmin = () => {
        if(secureStorage.getItem('userRole') === 'ADMIN') {
            return true
        } else {
            return false
        }
    }
    let merchant = selfMerchant
    let name
    if(!_.isEmpty(merchant) && isMerchant() === true && merchant.id !== undefined) {
        name = encodeBasicUrl(merchant.id)
    }
    const pathProps = {
        parsedToken,
        validAdmin: isAdmin
    }
    const pathPropsUser = {
        ...pathProps,
        isUserAdmin,
        isValidTermsAndConditions
    }
    const pathPropsContext = {
        ...pathProps,
        isUserAdmin
    }
    return (
        <div>
            
            <BrowserRouter history={useNavigate}>
                <ScrollToTop />
                <CatchAccessErrors />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path={ADMIN_PREFIX + '/login'} element={<Login adminLogin={true} />} />
                    <Route exact path='/callback' element={<Callback />} />
                    <Route exact path={ADMIN_PREFIX + '/callback'} element={<Callback adminLogin={true} />} />
                    <Route exact path='/403' element={<Error403 />} />
                    <Route path={ADMIN_PREFIX + '/'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <Dashboard />
                        </RequireAdminAuth>
                    } />
                    
                    <Route path={ADMIN_PREFIX + '/payments/:id'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <Dashboard />
                        </RequireAdminAuth>
                    } />
                    
                    <Route path={ADMIN_PREFIX + '/explorer'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <TransactionsExplorer />
                        </RequireAdminAuth>
                    } />
                    <Route exact path={ADMIN_PREFIX + '/merchants'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <Merchants />
                        </RequireAdminAuth>
                    } />
                    <Route path={ADMIN_PREFIX + '/merchants/:id'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <Merchant />
                        </RequireAdminAuth>
                    } />
                    <Route path={ADMIN_PREFIX + '/users'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <Users />
                        </RequireAdminAuth>
                    } />
                    <Route path={ADMIN_PREFIX + '/currencies'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <Currencies />
                        </RequireAdminAuth>
                    } />
                    <Route path={ADMIN_PREFIX + '*'} element={
                        <RequireAdminAuth validFunction={() => isTokenValid('admin')} {...pathProps}>
                            <NotFound />
                        </RequireAdminAuth>
                    } />
                    <Route path='/' element={
                        <RequireAuth validFunction={() => isTokenValid('merchant')} {...pathPropsUser}>
                            <Dashboard />
                        </RequireAuth>
                    } />
                    
                    <Route path='/explorer' element={
                        <RequireAuth validFunction={() => isTokenValid('merchant')} {...pathPropsUser}>
                            <TransactionsExplorer />
                        </RequireAuth>
                    } />
                    <Route exact path={'/keys'} element={
                        <RequireAuth validFunction={() => isTokenValid('merchant')} {...pathPropsUser}>
                            <Merchant />
                        </RequireAuth>
                    } />
                    <Route path={'/users'} element={
                        <RequireAuth validFunction={() => isTokenValid('merchant')} {...pathPropsUser}>
                            <Users />
                        </RequireAuth>
                    } />
                    <Route path={ADMIN_PREFIX + '/context'} element={
                        <RequireContextAuth validFunction={() => isTokenValid('admin')} {...pathPropsContext}>
                            <Dashboard />
                        </RequireContextAuth>
                    } />
                    <Route path={ADMIN_PREFIX + '/context/explorer'} element={
                        <RequireContextAuth validFunction={() => isTokenValid('admin')} {...pathPropsContext}>
                            <TransactionsExplorer />
                        </RequireContextAuth>
                    } />
                    <Route exact path={ADMIN_PREFIX + '/context/keys'} element={
                        <RequireContextAuth validFunction={() => isTokenValid('admin')} {...pathPropsContext}>
                            <Merchant />
                        </RequireContextAuth>
                    } />
                    <Route path={ADMIN_PREFIX + '/context/users'} element={
                        <RequireContextAuth validFunction={() => isTokenValid('admin')} {...pathPropsContext}>
                            <Users />
                        </RequireContextAuth>
                    } />
                    <Route path={'/terms-and-conditions'} element={
                        <RequireTerms validFunction={() => isTokenValid('merchant')} {...pathPropsUser}>
                            <TermsAndConditions />
                        </RequireTerms>
                    } />
                    <Route path={'*'} element={
                        <RequireAuth validFunction={() => isTokenValid('merchant')} {...pathPropsUser}>
                            <NotFound />
                        </RequireAuth>
                    } />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App


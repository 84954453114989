import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Textarea from 'react-validation/build/textarea'
import Button from 'react-validation/build/button'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ModalContainer from 'components/ui_elements/modal_container'
import DatepickerContainer from 'components/ui_elements/datepicker_container'
import Selectize from 'components/ui_elements/selectize'
import Tooltip from 'components/ui_elements/tooltip'
import { dateObjToDate, dateToIso } from 'helpers/date_helper'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import { required, number, maxlength, minValue } from 'helpers/validator_helper'
import { getMerchantsPaginated } from 'actions/merchantsPaginated'

const NewBalanceAdjustmentModal = ({onModalClosed, formSent, showOnInit}) => {
    const dispatch = useDispatch();
    const currencies = useSelector(state => state.currencies);
    const merchantsIdsPaginated = useSelector(state => state.merchantsIdsPaginated);
    let date = new Date();
    const [idempotencyKey, setIdempotencyKey] = useState("")
    const [state, setState] = useState({
        amount: '',
        currency: {},
        merchantId: {},
        asOfDate: dateObjToDate(date),
        adminComment: '',
        merchantComment: '',
        isFormDisabled: false,
        currencyPrecision: 2
    });
    useEffect(() => {
        dispatch(getMerchantsPaginated({
            limit: 128,
            offset: 0,
            select: true
        }));
    }, []);
    useEffect(() => {
        if(!_.isEmpty(state.currency)) {
            let currSettings = currencies.find(curr => curr.code === state.currency.value);
            if(currSettings) {
                setState((prevState) => ({
                    ...prevState,
                    currencyPrecision: currSettings.precision
                }));
            }
        }
    }, [state.currency]);
    useEffect(() => {
        updateIdempotency();
    }, [state.merchantId, state.asOfDate]);
    const handleModalHide = () => {
        onModalClosed();
    }
    const updateIdempotency = (el) => {
        const idDate = dateToIso(state.asOfDate)
        const merchantName = state.merchantId.value && merchantsIdsPaginated.length ? merchantsIdsPaginated.find(el => el.value === state.merchantId.value)?.label : 'merchant'
        let newKey = el ? el.target.value : merchantName + '-adj-' + idDate
        setIdempotencyKey(newKey)
    }
    const handleSelectChange = (val, e) => {
        setState((prevState) => ({
            ...prevState,
            [e]: {value: val, label: val}
        }));
        
    }
    const handleChange = (event) => {
        updateInputStateHook(setState, state, event);
    }
    const handleSubmit = (event) => {
        handleModalFormSubmitHook({ 
            event: event,  
            sendFunction: () => formSent({
                amount: state.amount, 
                currency: state.currency.value,
                merchantId: state.merchantId.value,
                asOfDate: dateToIso(state.asOfDate) + 'T00:00:00Z',
                adminComment: state.adminComment,
                merchantComment: state.merchantComment,
                idempotencyKey: idempotencyKey
            }),
            resetFunction: resetAndClose
        });
    }
    const handleDateChange = (e,el) => {
        setState((prevState) => ({
            ...prevState,
            asOfDate: el
        }));
    }
    const resetAndClose = () => {
        onModalClosed();
        $('#adjustmentModal').modal('hide');
    }
    const selectMounted = (el, id) => {
        setState((prevState) => ({
            ...prevState,
            [id]: el
        }));
    }
    return (
        <ModalContainer classNames='modal-md-s' thisId='adjustmentModal' title='New balance adjustment' onHide={handleModalHide} showInit={showOnInit} >
            <Form onSubmit={handleSubmit} id='adjustmentModalForm' className='basic-form'>
                 {merchantsIdsPaginated.length ? (
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group clearfix'>
                                    <label htmlFor='merchantId'>Merchant: *</label>
                                    <Selectize thisId='merchantId' dataOptions={merchantsIdsPaginated} onMounted={selectMounted} isSearchable={true} onChange={handleSelectChange} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <span></span>
                    )}
                    <div className='row no-mrg'>
                    {currencies.length ? (
                        <div className='col-lg-6 width-sm-screen-12 no-pdd-left pdd-right-7 no-pdd-sm-screen'>
                            <div className='form-group clearfix'>
                                <label htmlFor='currency'>Currency: *</label>
                                <Selectize thisId='currency' dataOptions={currencies.map(el => ({label: el.code, value: el.code}))} onMounted={selectMounted} isSearchable={true} onChange={handleSelectChange} />
                            </div>
                        </div>
                    ) : (
                        <span></span>
                    )}
                    <div className='col-lg-6 width-sm-screen-12 no-pdd-right pdd-lrft-7 no-pdd-sm-screen'>
                        <div className='form-group'>
                            <label htmlFor='amount'>Amount: *</label>
                            <Input type='text' className='form-control' id='amount' name='amount' data-precision={state.currencyPrecision} validations={[required, number, minValue]} data-min={0} data-min-label={0} data-currency={state.currency.value} value={state.amount} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <DatepickerContainer label='As of date *' thisId='asOfDate' onChange={handleDateChange} date={state.asOfDate} noEndDate={true} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='idempotencyKey'>Idempotence key *: <Tooltip id='idempotencyKeyTooltip' placement='top' text='This should be a unique key, preventing system from processing this request twice' /></label>
                            <Input type='text' className='form-control' id='idempotencyKey' name='idempotencyKey' validations={[required]} value={idempotencyKey} onChange={updateIdempotency} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='adminComment'>Admin comment *: <Tooltip id='adminCommentTooltip' placement='top' text='The comment only shown to the admin. Merchant will not see it' /></label>
                            <Textarea rows='3' className='form-control textarea-small' id='adminComment'  validations={[maxlength, required]} name='adminComment' data-maxlength='150' data-name='comment' value={state.adminComment} onChange={handleChange}></Textarea>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='merchantComment'>Merchant comment *: <Tooltip id='merchantCommentTooltip' placement='top' text='The comment shown to the merchant' /></label>
                            <Textarea rows='3' className='form-control textarea-small' id='merchantComment'  validations={[maxlength, required]} name='merchantComment' data-maxlength='150' data-name='comment' value={state.merchantComment} onChange={handleChange}></Textarea>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p className='mrg-btm-5'>* - Field is required</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' type='submit' disabled>Create adjustment</Button>
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
    
}

NewBalanceAdjustmentModal.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default NewBalanceAdjustmentModal;
